import * as React from 'react';
import i18next from "i18next";

import Card from '../../../components/card/container/card-container';
import PjIcon from '../../../components/icons/svg-icon';
import * as servicesStyles from './services.module.scss';

const Services = () => {
    const services = React.useMemo(()=>{
        return  i18next
            .t('services.services', { returnObjects: true })
            ?.reduce((p, c)=> {
                if (p[p.length - 1].length < 4){
                    p[p.length-1].push(c);
                } else {
                    p.push([c]);
                }
                return p;
            }, [[]]) ?? [];
    }, []);
    return (
        <section className={`${servicesStyles.services} p-4r font-s3r text-center`}>
            <h1 className="mb-2r">{i18next.t('services.title')}</h1>
            {services.map((services, index) => {
                return <div key={services.toString() + index} className="row w-80 p-1r flex-jc">
                    {
                        services.map((service, index) => {
                            return <div key={service.name.toString() + service.desc.toString() + index}
                                        className="col-1-of-4">
                                <Card
                                    body={
                                        <Card.body className="text-center">
                                            <PjIcon iconName={service.name} width="7em" height="7em"></PjIcon>
                                            <h4>{service.desc}</h4>
                                        </Card.body>
                                    }
                                >
                                </Card>
                            </div>
                        })}

                </div>

            })}

        </section>
    )
}
export default Services
